import { Dependencies } from 'constitute';
import Container from '@/Framework/DI/Container';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import DataroomRpcDispatcher from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import Rpc, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { ICreateSessionPayload } from './interfaces';

@Dependencies(Container)
class SessionRepository {
  private dataroomHttpDispatcher: (tenant: string) => Rpc;

  constructor(container) {
    container.schedulePostConstructor(
      (dataroomHttpDispatcher) => {
        this.dataroomHttpDispatcher = dataroomHttpDispatcher;
      }, [DataroomRpcDispatcher]);
  }

  /** Create session for dataroom */
  async createDataroomSession(data: ICreateSessionPayload, tenant: DataroomTenant): Promise<string> {
    let req = new Request('user.authenticate', data);
    let response = await this.dataroomHttpDispatcher(tenant).call<RpcSuccess>(req);

    // @ts-ignore
    return response.getResult().ssid;
  }

  /**
   * Verify that current session is valid for DealVDR
   *
   * @return {Promise<*>}
   */
  async verifyDataroomSession(tenant: DataroomTenant) {
    let req = new Request('user.verify_session');
    let response = await this.dataroomHttpDispatcher(tenant).call<RpcSuccess>(req);

    return response.getResult();
  }
}

export default SessionRepository;
